import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, LinearProgress, IconButton, Paper, Grid, Tooltip, Fade, Avatar, useMediaQuery, ClickAwayListener } from '@mui/material';
// import { styled } from '@mui/system';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BusinessIcon from '@mui/icons-material/Business';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import PersonIcon from '@mui/icons-material/Person';
import FilterListIcon from '@mui/icons-material/FilterList';
import MapIcon from '@mui/icons-material/Map';
import SavingsIcon from '@mui/icons-material/Savings';
import SpeedIcon from '@mui/icons-material/Speed';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import WorkIcon from '@mui/icons-material/Work';
import GroupIcon from '@mui/icons-material/Group';
import PeopleIcon from '@mui/icons-material/People';
import { styled, useTheme } from '@mui/system';
import mattImage from '../assets/matt.jpeg';
import amanImage from '../assets/aman.jpeg';
import BarChartIcon from '@mui/icons-material/BarChart';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import BuildIcon from '@mui/icons-material/Build';
import OfferIcon from '@mui/icons-material/LocalOffer';
import AssessmentIcon from '@mui/icons-material/Assessment';
import GroupsIcon from '@mui/icons-material/Groups';
import StorageIcon from '@mui/icons-material/Storage';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

// Import your WISSP.AI logo
import wisspLogo from '../assets/wissp-logo.png';

const PreviewBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    width: '100px',
    height: '60px',
    background: '#000000',
    border: '1px solid #c02f5a',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    transform: 'translateY(-50%)',
    transition: 'opacity 0.3s ease-in-out',
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  }));

const ResponsiveTypography = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.1rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem',
    },
  }));
  
const ResponsiveBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  }));

const StyledBox = styled(Box)(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#000000',
  color: '#ffffff',
  position: 'relative',
  overflow: 'hidden',
}));

const MainContentBox = styled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: '800px',
    margin: '0 auto',
  }));
  
  const ExpandableBox = styled(Box)(({ theme }) => ({
    height: '200px',
    overflow: 'hidden',
    transition: 'height 0.3s ease-in-out',
    '&.expanded': {
      height: 'auto',
      maxHeight: '70vh',
      overflowY: 'auto',
    },
  }));


const HighlightedTypography = styled(Typography)(({ theme }) => ({
    '& .highlight': {
      color: '#c02f5a',
      fontWeight: 'bold',
    },
  }));

const ContentBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4),
  transition: 'opacity 0.5s ease-in-out',
}));

const Logo = styled('img')({
  position: 'absolute',
  top: 20,
  left: 20,
  height: 40,
});

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: '4px',
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#c02f5a',
  },
}));

const NavButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  color: '#c02f5a',
}));

const MainStyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    textAlign: 'center',
    color: '#ffffff',
    background: 'linear-gradient(135deg, #000000 0%, #3a0d1b 70%, #c02f5a 100%)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 0 20px rgba(192, 47, 90, 0.5)',
    },
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: -1,
      padding: '2px',
      borderRadius: 'inherit',
      background: 'linear-gradient(45deg, #c02f5a, #f0d794, #72d6bf, #db6b5c)',
      WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude',
    },
  }));
  
  const SubStyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    textAlign: 'center',
    color: '#ffffff',
    background: '#000000',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 0 20px rgba(240, 215, 148, 0.5)',
    },
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'linear-gradient(135deg, #f0d794 0%, #d6b168 100%)',
      opacity: 0.15,
      borderRadius: 'inherit',
      zIndex: 0,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'radial-gradient(circle at corners, #f0d794 0%, transparent 70%)',
      opacity: 0.2,
      borderRadius: 'inherit',
      zIndex: 1,
    },
    '& > *': {
      zIndex: 2,
    },
  }));

  const ResponsiveAvatar = styled(Avatar)(({ theme }) => ({
    width: 80,
    height: 80,
    [theme.breakpoints.up('sm')]: {
      width: 100,
      height: 100,
    },
    [theme.breakpoints.up('md')]: {
      width: 120,
      height: 120,
    },
    margin: '0 auto',
    border: '3px solid #c02f5a',
  }));
// const StyledPaper = styled(Paper)(({ theme }) => ({
//     padding: theme.spacing(3),
//     textAlign: 'center',
//     color: '#ffffff',
//     background: 'linear-gradient(135deg, #000000 0%, #3a0d1b 70%, #c02f5a 100%)',
//     height: '100%',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
//     '&:hover': {
//       transform: 'scale(1.05)',
//       boxShadow: '0 0 20px rgba(192, 47, 90, 0.5)',
//     },
//     position: 'relative',
//     '&::before': {
//       content: '""',
//       position: 'absolute',
//       top: 0,
//       right: 0,
//       bottom: 0,
//       left: 0,
//       zIndex: -1,
//       padding: '2px',
//       borderRadius: 'inherit',
//       background: 'linear-gradient(45deg, #c02f5a, #f0d794, #72d6bf, #db6b5c)',
//       WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
//       WebkitMaskComposite: 'xor',
//       maskComposite: 'exclude',
//     },
//   }));

const InteractiveWisspAI = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showSubSlides, setShowSubSlides] = useState(false);
  const [fadeState, setFadeState] = useState('in');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedBox, setExpandedBox] = useState(null);
  const touchStartX = useRef(null);
  const touchStartY = useRef(null);
  const [isTouching, setIsTouching] = useState(false);
  const [touchStart, setTouchStart] = useState({ x: 0, y: 0 });
  const [touchEnd, setTouchEnd] = useState({ x: 0, y: 0 });

  const handleTouchStart = (e) => {
    setIsTouching(true);
    setTouchStart({
      x: e.touches[0].clientX,
      y: e.touches[0].clientY
    });
  };

  const handleTouchMove = (e) => {
    if (!isTouching) return;
    setTouchEnd({
      x: e.touches[0].clientX,
      y: e.touches[0].clientY
    });
  };

  const handleTouchEnd = () => {
    setIsTouching(false);
    const deltaX = touchStart.x - touchEnd.x;
    const deltaY = touchStart.y - touchEnd.y;

    if (Math.abs(deltaX) < 5 && Math.abs(deltaY) < 5) {
      // This was a tap, not a swipe
      return;
    }

    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      // Horizontal swipe
      if (deltaX > 50) {
        handleNavigation('next');
      } else if (deltaX < -50) {
        handleNavigation('prev');
      }
    } else {
      // Vertical swipe
      if (deltaY > 50) {
        handleNavigation('down');
      } else if (deltaY < -50) {
        handleNavigation('up');
      }
    }
  };

//   const handleTouchStart = (e) => {
//     touchStartX.current = e.touches[0].clientX;
//     touchStartY.current = e.touches[0].clientY;
//   };

//   const handleTouchEnd = (e) => {
//     if (!touchStartX.current || !touchStartY.current) return;

//     const touchEndX = e.changedTouches[0].clientX;
//     const touchEndY = e.changedTouches[0].clientY;

//     const deltaX = touchStartX.current - touchEndX;
//     const deltaY = touchStartY.current - touchEndY;

//     if (Math.abs(deltaX) > Math.abs(deltaY)) {
//       // Horizontal swipe
//       if (deltaX > 50) {
//         handleNavigation('next');
//       } else if (deltaX < -50) {
//         handleNavigation('prev');
//       }
//     } else {
//       // Vertical swipe
//       if (deltaY > 50) {
//         handleNavigation('down');
//       } else if (deltaY < -50) {
//         handleNavigation('up');
//       }
//     }

//     touchStartX.current = null;
//     touchStartY.current = null;
//   };

  const slides = [
    {
      title: "The <span class='highlight'>AI Challenge</span>",
      content: "Most companies struggle to make sense of the AI hype. Where do you even start?",
      icon: <BusinessIcon sx={{ fontSize: 60, color: '#c02f5a' }} />
    },
    {
      title: "A Small Change, <span class='highlight'>Big Impact</span>",
      content: "Did you know? Saving just 11 minutes per day makes employees more productive and happier.",
      icon: <AccessTimeIcon sx={{ fontSize: 60, color: '#c02f5a' }} />
    },
    {
      title: "Introducing <span class='highlight'>WISSP.AI</span>",
      content: "We provide meaningful AI services to support your daily workflows.",
      icon: <EmojiObjectsIcon sx={{ fontSize: 60, color: '#c02f5a' }} />
    },
    {
        title: "Cutting Through the <span class='highlight'>Noise</span>",
        content: "We help you ask the right questions, explore the right technologies, and prioritize effectively.",
        icon: <FilterListIcon sx={{ fontSize: 60, color: '#c02f5a' }} />
      },
    {
      title: "Your <span class='highlight'> Outsourced AI Officer</span>",
      content: "What if you have access to an AI expert to strategize and execute your AI strategy for real results.",
      icon: <PersonIcon sx={{ fontSize: 60, color: '#c02f5a' }} />,
      subSlides: [
        { title: "<span class='highlight'> Quantified  </span> Benefits", content: "We provide AI strategy identifying measurable results that lead to business outcomes.", icon: <SavingsIcon sx={{ fontSize: 60, color: '#c02f5a' }} /> },
        { title: "Time <span class='highlight'> Efficiency</span>", content: "We bring capabilities to build or assist your teams in building the tech to reduce time wastage in implementation.", icon: <AccessTimeIcon sx={{ fontSize: 60, color: '#c02f5a' }} /> },
        { title: "Gain <span class='highlight'> Experience</span>", content: "Our methodology is grounded in value across end-to-end business processes.", icon: <TrendingUpIcon sx={{ fontSize: 60, color: '#c02f5a' }} /> }
      ]
    },
    {
        title: "AI in <span class='highlight'>Action</span>",
        content: "For example, we re-defined data processing for a leading ad agency, turning month-long tasks into minutes.",
        icon: <BarChartIcon sx={{ fontSize: 60, color: '#c02f5a' }} />,
        subSlides: [
        { title: "From <span class='highlight'>30 Days</span> to 30 Minutes", content: "Dramatically accelerated data processing and insights generation", icon: <SpeedIcon sx={{ fontSize: 60, color: '#c02f5a' }} /> },
        { title: "<span class='highlight'>Seamless</span> Data Integration", content: "Automated complex data taxonomy powered by AI mapping across platforms", icon: <IntegrationInstructionsIcon sx={{ fontSize: 60, color: '#c02f5a' }} /> },
        { title: "<span class='highlight'>Real-time</span> Campaign Optimization", content: "Enabled AI led data-driven decisions for better ROI", icon: <TrendingUpIcon sx={{ fontSize: 60, color: '#c02f5a' }} /> }
        ]
      },

    {
      title: "The <span class='highlight'>WISSP.AI </span> Advantage",
      content: "That's not all - our AI advantage gives you access to AI experts, cutting edge Tech and proven methods at fraction of the cost.",
      icon: <SavingsIcon sx={{ fontSize: 60, color: '#c02f5a' }} />,
      subSlides: [
        { 
          title: "Your <span class='highlight'>AI Roadmap</span>", 
          content: "We develop an AI strategy aligned with your unique business goals.",
          icon: <MapIcon sx={{ fontSize: 60, color: '#c02f5a' }} />
        },
        { 
          title: "<span class='highlight'>Rapid</span> Results", 
          content: "We build and iterate with fully functional proofs within 3 months not 3 to 4 quarters.",
          icon: <SpeedIcon sx={{ fontSize: 60, color: '#c02f5a' }} />
        },
        { 
          title: "Enhancing Your <span class='highlight'>Strengths</span>", 
          content: "We don't change what you're good at. We help you differentiate that with AI.",
          icon: <TrendingUpIcon sx={{ fontSize: 60, color: '#c02f5a' }} />
        }
      ]
    },
    {
        title: "Our <span class='highlight'>Offerings</span>",
        content: "Tailored AI solutions to meet your specific needs and resources.",
        icon: <OfferIcon sx={{ fontSize: 60, color: '#c02f5a' }} />,
        subSlides: [
            {
            title: "<span class='highlight'>AI Strategy</span>",
            content: "Don't know where to start? Interested in learning what’s possible or want to confirm your ideas? We’ll craft your AI roadmap, aligning cutting edge tech with your business goal.",
            icon: <LightbulbIcon sx={{ fontSize: 60, color: '#c02f5a' }} />,
            description: "Perfect for businesses unsure about AI implementation or lacking resources for strategy development."
            },
            {
            title: "<span class='highlight'>AI Execution</span>",
            content: "Have a plan but dont have the right resourcing or enough resources? We’ll bring you AI vision to life.",
            icon: <BuildIcon sx={{ fontSize: 60, color: '#c02f5a' }} />,
            description: "Ideal for companies with a strategy in place but without the internal resources for execution."
            }
        ]
      },

      {
        title: "Is your business <span class='highlight'>AI Ready?</span>",
        content: "Discover where you stand on the AI readiness spectrum and chart your path forward",
        icon: <AssessmentIcon sx={{ fontSize: 60, color: '#c02f5a' }} />,
        subSlides: [
          { 
            title: "<span class='highlight'>Stakeholder </span> Insights", 
            content: "Surveys and interviews with key stakeholders to understand your unique needs and challenges.", 
            icon: <GroupsIcon sx={{ fontSize: 60, color: '#c02f5a' }} /> 
          },
          { 
            title: "<span class='highlight'>Data Readiness</span> Assessment", 
            content: "Analysis of existing data sets and schemas to assess AI readiness and potential.", 
            icon: <StorageIcon sx={{ fontSize: 60, color: '#c02f5a' }} /> 
          },
          { 
            title: "Workflow <span class='highlight'>Optimization</span> Mapping", 
            content: "Mapping of current state workflows and identification of key tasks for AI optimization.", 
            icon: <AccountTreeIcon sx={{ fontSize: 60, color: '#c02f5a' }} /> 
          }
        ]
    },


    {
        title: "WISSP <span class='highlight'> Showcase </span>",
        content: "Discover some of our cutting-edge AI solutions",
        icon: <WorkIcon sx={{ fontSize: 60, color: '#c02f5a' }} />,
        subSlides: [
          { title: "Smart <span class='highlight'> Search</span> ", content: "AI-driven systems that help you smartly discover information across tons of data sources.", icon: <WorkIcon sx={{ fontSize: 60, color: '#c02f5a' }} /> },
          { title: "Personal <span class='highlight'> AI Analyst </span> ", content: "AI generated knowledge and goals to simplify insights extraction from huge data.", icon: <WorkIcon sx={{ fontSize: 60, color: '#c02f5a' }} /> },
          { title: "<span class='highlight'> Synthetic Data </span> Generator", content: "Leverage synthetic data and take privacy under your control.", icon: <WorkIcon sx={{ fontSize: 60, color: '#c02f5a' }} /> }
        ]
      },


    {
      title: "<span class='highlight'>Client </span>Testimonials",
      content: "Hear what our clients have to say about us",
      icon: <FormatQuoteIcon sx={{ fontSize: 60, color: '#c02f5a' }} />,
      subSlides: [
        { title: "Brian Chap, <span class='highlight'> CEO, Tech Recipes</span>", content: "WISSP.AI transformed our approach to data processing with The Root. We are able to provide outcomes 80% faster with AI powered insights", icon: <FormatQuoteIcon sx={{ fontSize: 60, color: '#c02f5a' }} /> },
        { title: "Consultant, <span class='highlight'>US Govt.</span>", content: "The expertise and dedication of the WISSP.AI team have been invaluable to our AI journey. Our ability to extract insights from complex research papers has increased our efficiency 2x", icon: <FormatQuoteIcon sx={{ fontSize: 60, color: '#c02f5a' }} /> },
        // { title: "Future Dynamics <span class='highlight'>Ltd.</span>", content: "WISSP.AI's outsourced AI officer became an indispensable part of our innovation strategy.", icon: <FormatQuoteIcon sx={{ fontSize: 60, color: '#c02f5a' }} /> }
      ]
    },
    {
        title: "About <span class='highlight'>Us</span>",
        content: "Meet the visionaries behind WISSP.AI",
        icon: <GroupIcon sx={{ fontSize: 60, color: '#c02f5a' }} />,
        subSlides: [
            {
                title: "<span class='highlight'>Founders</span>",
                icon: <PeopleIcon sx={{ fontSize: 60, color: '#c02f5a' }} />,
                content: (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Avatar
                        src={mattImage}
                        alt="Matt Sotebeer"
                        sx={{ 
                            width: 120, 
                            height: 120, 
                            margin: '0 auto',
                            border: '3px solid #c02f5a'
                        }}
                        />
                      <ResponsiveTypography variant="h6" sx={{ mt: 2 }}>Matt Sotebeer</ResponsiveTypography>
                      <ResponsiveTypography variant="body2">
                        Founder and CEO<br/>
                        Former CSO, Revolutionizing data-driven decision-making and bridging the gap between AI perception and reality  <br/>
                        Pioneering AI leader with 20+ years experience
                      </ResponsiveTypography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Avatar
                        src={amanImage}
                        alt="Amandeep Singh"
                        sx={{ 
                            width: 120, 
                            height: 120, 
                            margin: '0 auto',
                            border: '3px solid #c02f5a'
                        }}
                        />
                      <ResponsiveTypography variant="h6" sx={{ mt: 2 }}>Amandeep Singh</ResponsiveTypography>
                      <ResponsiveTypography variant="body2">
                        Co-founder & COO<br/>
                        Innovation Architect, IITian <br/>
                        10+ years building transformative AI tech, building high-performing teams, and driving impactful decision-making across organizations.
                      </ResponsiveTypography>
                    </Grid>
                  </Grid>
                )
              },
          {
            title: "<span class='highlight'>Who</span> We Are",
            icon: <EmojiObjectsIcon sx={{ fontSize: 60, color: '#c02f5a' }} />,
            content: (
            <ResponsiveTypography variant="body1" align="left">
                <p>At WISSP.AI, we believe in harnessing the power of AI to enhance human potential. Our mission is to simplify complex processes, allowing individuals and organizations to focus on what truly matters: meaningful connections and impactful work.</p>
                
                <p>With over three decades of combined experience, our founders have collaborated with global industry leaders, implementing cutting-edge AI and ML solutions to address critical enterprise challenges. This wealth of expertise allows us to navigate the complexities of AI integration seamlessly.</p>
                
                <p>Our leadership team has a proven track record of building and nurturing high-performance teams and products. We've consistently driven sustainable growth for organizations, balancing innovation with practical, results-driven strategies.</p>
                
                <p>Currently, we're proud to be partnering with industry titans across technology, finance, and healthcare sectors, helping them leverage AI to stay at the forefront of their respective fields. Our tailored solutions are driving efficiency, fostering innovation, and creating measurable impact across diverse industries.</p>
                
                <p>At WISSP.AI, we're not just implementing technology; we're shaping the future of work and human interaction in the age of AI.</p>
              </ResponsiveTypography>
            )
          },
          {
            title: "Our <span class='highlight'>Expertise</span>",
            icon: <WorkIcon sx={{ fontSize: 60, color: '#c02f5a' }} />,
            content: (
              <ResponsiveTypography variant="body1" align="left">
                <p>WISSP.AI brings together a diverse team of experts across India and the United States, offering a unique blend of global perspective and deep technical knowledge. Our team of specialists excels in:</p>
                
                <ul>
                  <li><strong>AI/ML Engineering, Frontend & Backend Development, Data Science, Cloud Architecture, UI/UX Design, DevOps</strong></li>
                  {/* <li><strong>:</strong> Building robust, scalable systems that power AI-driven applications.</li>
                  <li><strong>:</strong> Developing cutting-edge algorithms and models tailored to specific business needs.</li>
                  <li><strong>:</strong> Extracting actionable insights from complex datasets to drive informed decision-making.</li>
                  <li><strong>:</strong> Designing and implementing secure, efficient cloud infrastructures for AI workloads.</li>
                  <li><strong>:</strong> Ensuring smooth deployment and operation of AI systems in production environments.</li> */}
                </ul>
                
                <p>Our collective experience spans from innovative startups to Fortune 500 companies, enabling us to deliver sophisticated AI solutions that are both innovative and pragmatic. We pride ourselves on our ability to translate complex AI concepts into tangible business value, helping our clients stay ahead in the rapidly evolving digital landscape.</p>
                
                <p>By choosing WISSP.AI, you're not just getting a service provider; you're partnering with a team of dedicated experts committed to driving your AI transformation journey.</p>
              </ResponsiveTypography>
            )
          }
        ]
      }  
    ];

  const getPlainTitle = (title) => {
    return title.replace(/<\/?span[^>]*>/g, "");
  };

  const handleNavigation = (direction) => {
    setFadeState('out');
    setTimeout(() => {
      if (direction === 'next' && currentSlide < slides.length - 1) {
        setCurrentSlide(currentSlide + 1);
        setShowSubSlides(false);
      } else if (direction === 'prev' && currentSlide > 0) {
        setCurrentSlide(currentSlide - 1);
        setShowSubSlides(false);
      } else if (direction === 'down') {
        setShowSubSlides(true);
      } else if (direction === 'up') {
        setShowSubSlides(false);
      }
      setFadeState('in');
    }, 500);
  };
  const handleBoxClick = (index) => {
    setExpandedBox(expandedBox === index ? null : index);
  };

  const handleClickAway = () => {
    setExpandedBox(null);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') handleNavigation('next');
      if (event.key === 'ArrowLeft') handleNavigation('prev');
      if (event.key === 'ArrowDown') handleNavigation('down');
      if (event.key === 'ArrowUp') handleNavigation('up');
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentSlide, showSubSlides]);

  const renderContent = () => {
    const slide = slides[currentSlide];
    if (!showSubSlides || !slide.subSlides) {
      return (
        <MainContentBox>
          <MainStyledPaper elevation={3}>
            {slide.icon}
            <ResponsiveTypography variant="h5" sx={{ mt: 2 }}>
              {slide.content}
            </ResponsiveTypography>
          </MainStyledPaper>
        </MainContentBox>
      );
    } else {
      return (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Grid container spacing={4} justifyContent="center">
            {slide.subSlides.map((subSlide, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <SubStyledPaper 
                  elevation={3} 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleBoxClick(index);
                  }}
                  sx={{ cursor: 'pointer', height: '100%' }}
                >
                  {subSlide.icon && React.cloneElement(subSlide.icon, { sx: { fontSize: 40, color: '#c02f5a' } })}
                  <HighlightedTypography 
                    variant="h6" 
                    sx={{ 
                      mt: 2, 
                      mb: 2,
                      fontWeight: 'bold', 
                      color: '#ffffff', 
                      fontSize: isMobile ? '1.2rem' : '1.5rem',
                      height: '3em',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }} 
                    dangerouslySetInnerHTML={{ __html: subSlide.title }} 
                  />
                  <ExpandableBox 
                    className={expandedBox === index ? 'expanded' : ''}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {typeof subSlide.content === 'string' ? (
                      <ResponsiveTypography variant="body1" sx={{ color: '#ffffff' }}>
                        {subSlide.content}
                      </ResponsiveTypography>
                    ) : (
                      <Box>{subSlide.content}</Box>
                    )}
                  </ExpandableBox>
                </SubStyledPaper>
              </Grid>
            ))}
          </Grid>
        </ClickAwayListener>
      );
    }
  };

  return (
    <StyledBox
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <Logo src={wisspLogo} alt="WISSP.AI Logo" />
      <ContentBox 
        style={{ opacity: fadeState === 'in' ? 1 : 0 }}
        sx={{ 
          overflowY: 'auto', 
          maxHeight: '100vh', 
          WebkitOverflowScrolling: 'touch' 
        }}
      >
        <HighlightedTypography 
          variant="h2" 
          component="h1" 
          gutterBottom 
          sx={{ 
            mb: 4, 
            color: '#f0d794', 
            fontWeight: 'bold',
            fontSize: isMobile ? '2rem' : '3rem',
            textAlign: 'center'
          }} 
          dangerouslySetInnerHTML={{ __html: slides[currentSlide].title }} 
        />
        <ResponsiveBox 
          sx={{ 
            overflowY: 'auto', 
            maxHeight: '70vh', 
            WebkitOverflowScrolling: 'touch',
            touchAction: 'pan-y'
          }}
        >
          {renderContent()}
        </ResponsiveBox>
      </ContentBox>
    <Tooltip title={currentSlide > 0 ? getPlainTitle(slides[currentSlide - 1].title) : ''} placement="right">
      <NavButton 
        onClick={() => handleNavigation('prev')} 
        style={{ left: 20 }}
        disabled={currentSlide === 0}
      >
        <KeyboardArrowLeftIcon />
        {currentSlide > 0 && (
          <PreviewBox style={{ right: '100%' }}>
            <Typography variant="caption">{getPlainTitle(slides[currentSlide - 1].title)}</Typography>
          </PreviewBox>
        )}
      </NavButton>
    </Tooltip>
    <Tooltip title={currentSlide < slides.length - 1 ? getPlainTitle(slides[currentSlide + 1].title) : ''} placement="left">
      <NavButton 
        onClick={() => handleNavigation('next')} 
        style={{ right: 20 }}
        disabled={currentSlide === slides.length - 1}
      >
        <KeyboardArrowRightIcon />
        {currentSlide < slides.length - 1 && (
          <PreviewBox style={{ left: '100%' }}>
            <Typography variant="caption">{getPlainTitle(slides[currentSlide + 1].title)}</Typography>
          </PreviewBox>
        )}
      </NavButton>
    </Tooltip>
      {slides[currentSlide].subSlides && !showSubSlides && (
        <Fade in={fadeState === 'in'}>
          <IconButton
            onClick={() => handleNavigation('down')}
            sx={{ position: 'absolute', bottom: 20, left: '50%', transform: 'translateX(-50%)', color: '#c02f5a' }}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </Fade>
      )}
      {showSubSlides && (
        <Fade in={fadeState === 'in'}>
          <IconButton
            onClick={() => handleNavigation('up')}
            sx={{ position: 'absolute', bottom: 20, left: '50%', transform: 'translateX(-50%)', color: '#c02f5a' }}
          >
            <KeyboardArrowUpIcon />
          </IconButton>
        </Fade>
      )}
      <ProgressBar 
        variant="determinate" 
        value={(currentSlide / (slides.length - 1)) * 100} 
      />
    </StyledBox>
  );
};

export default InteractiveWisspAI;